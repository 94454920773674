import React, { useState } from "react";
import ProductAuthentication from "./components/ProductAuthentication";

const App = () => {

  return (
    <div>
      <ProductAuthentication/>
    </div>
  );
};

export default App;
