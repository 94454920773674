import React, { useState } from "react";
import SplashScreen from "./SplashScreen";

const ProductAuthentication = () => {
  const [showDetails, setShowDetails] = useState(true);
  const [phoneNo, setPhoneNo] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [isFake, setIsFake] = useState(false);
  const [isScanned, setIsScanned] = useState(false);
  const [showSplash, setShowSplash] = useState(true);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const handlePhoneSubmit = () => {
    setShowWarning(true);
  };

    const handleAnimationComplete = () => {
      setShowSplash(false);
    };

  return (
    <div className="bg-gray-100 min-h-screen flex justify-center items-center p-4">
      
          {!isFake ? (
            <div className="max-w-4xl w-full bg-white p-4 shadow-md rounded-lg">
              <div className="text-center mb-4">
                <h1 className="text-xl font-bold">PRODUCT AUTHENTICATION</h1>
                <div className="text-sm text-gray-600 mt-2">
                  <p>पहली बार स्कैन किया गया था दिनांक 08 Jul 2024 को</p>
                  <p>कुल स्कैन किया गया है: 20 बार</p>
                  {isScanned ? (
                    <p className="text-xl font-bold mt-2 text-red-500">
                      यह उत्पाद स्कैन हो चुका है।
                    </p>
                  ) : (
                    <div className="flex items-center justify-center mt-2">
                      <img
                        src={require("../image/asli_utpad_logo.png")} // Replace with your logo path
                        alt="Logo"
                        className="h-14 w-14 mr-2"
                      />
                      <p className="text-lg font-bold text-green-500">
                        असली उत्पाद खरीद ने के लिए धन्यवाद
                      </p>
                      <img
                        src={require("../image/asli_utpad_logo.png")} // Replace with your logo path
                        alt="Logo"
                        className="h-14 w-14 ml-2"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-center items-center mb-4">
                <img
                  src={require("../image/brand_image.jpeg")}
                  alt="Product"
                  className="max-w-full h-auto"
                />
              </div>
              <div className="text-center mb-4">
                <h2 className="text-lg font-semibold mb-2">पत्थर बीड़ी 702.</h2>
                <button
                  onClick={toggleDetails}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                >
                  {showDetails ? "Hide Details" : "Show Details"}
                </button>
              </div>
              {showDetails && (
                <div>
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border border-gray-400 rounded">
                    <thead className="text-xs bg-gray-200 text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th colSpan="2" className="px-6 py-3 text-center">
                          Product Information
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          Brand
                        </th>
                        <td className="px-6 py-4">पत्थर 702 बीड़ी</td>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          Company
                        </th>
                        <td className="px-6 py-4">
                          ओ टू इंडस्ट्रीज प्राइवेट लिमिटेड
                        </td>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          UI Number
                        </th>
                        <td className="px-6 py-4">00005-115468</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              <div className="text-center mt-4">
                <h5 className="text-lg font-semibold">Customer Care No</h5>
                <p className="text-lg">9999999999</p>
              </div>
            </div>
          ) : (
            <div className="max-w-4xl w-full bg-white p-4 shadow-md rounded-lg">
              {!showWarning ? (
                <div className="flex flex-col items-center">
                  <h2 className="text-xl font-bold mb-4">
                    Enter Phone No to proceed
                  </h2>
                  <input
                    type="text"
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    className="p-2 border border-gray-400 rounded mb-4"
                  />
                  <button
                    onClick={handlePhoneSubmit}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              ) : (
                <div className="text-center">
                  <h1 className="text-4xl font-bold text-red-500 mb-4">
                    सावधान
                  </h1>
                  <p className="text-xl font-bold text-red-500 mb-4">
                    आपका ख़रीदा हुआ उत्पाद फ़र्ज़ी हो सकता है। कृपया निचे दिए हुए
                    नंबर पर कॉल करे।
                  </p>
                  <div className="text-center mt-4">
                    <h5 className="text-lg font-semibold">Customer Care No</h5>
                    <p className="text-lg">9999999999</p>
                  </div>
                </div>
              )}
            </div>
          )}

    </div>
  );
};

export default ProductAuthentication;
