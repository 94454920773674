import React, { useEffect } from "react";
import { motion } from "framer-motion";

const SplashScreen = ({ onAnimationComplete }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onAnimationComplete();
    }, 3000); // Change this to control the duration of the splash screen

    return () => clearTimeout(timer);
  }, [onAnimationComplete]);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <motion.img
        src={require("../image/asli_utpad_logo.png")}
        alt="Splash Screen"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 3 }}
        className="object-contain max-w-full max-h-full"
      />
    </div>
  );
};

export default SplashScreen;
